import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import QRCode from 'qrcode.react';
import { Table, Button, Modal, Form, Dropdown, Navbar, Container, Row, Col, Alert } from 'react-bootstrap';
import { createSample, createTest, createWorkOrder, fetchTestTemplates, updateSubmission, fetchGroups, fetchMatrices, fetchSubmission, fetchLatestWorkOrder, fetchLatestSample } from '../api';
import { useLoading } from '../contexts/LoadingContext';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import sha256 from 'crypto-js/sha256';

const cookies = new Cookies();

const CheckIn = () => {
  const [samples, setSamples] = useState([]);
  const [tests, setTests] = useState([]);
  const [groups, setGroups] = useState([]);
  const [code, setCode] = useState(null);
  const [matrices, setMatrices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingSample, setEditingSample] = useState(null);
  const [clientInformation, setClientInformation] = useState(null);
  const [formData, setFormData] = useState({
    sampleName: '',
    groupType: 'FOOD',
    matrixType: 'BEVERAGE',
    lotOrBatch: '',
    quantityProvided: 0,
    unit: 'ml',
    sampleStorageConditions: 'AMBIENT',
    testsRequested: [],
    turnaroundTime: 'STANDARD',
    notes: '',
  });
  const [labData, setLabData] = useState({
    sendDate: '',
    receivingPersonnel: '',
    conditionReceived: '',
    dateTimeReceived: '',
    storageLocation: '',
    signature: '',
    assignedJobId: '',
  });
  const [submissionId, setSubmissionId] = useState(null);
  const [codeId, setCodeId] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { setLoading } = useLoading();
  const submission_id = useParams()['id'];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (cookies.get('cora_science_admin') && sha256(cookies.get('cora_science_admin')).toString() === '26514fcfabb61114a12bfbfa876e863e17538bb3f66fe1fabc0e70d6b22f3ae4') {
          const test_templates = await fetchTestTemplates();
          setTests(test_templates.filter(test_template => test_template.published));
          const groups = await fetchGroups();
          setGroups(groups);
          const matrices = await fetchMatrices();
          setMatrices(matrices);
          const response = await fetchSubmission(submission_id);
          setFormData({ ...formData, 'groupType': groups[0].name.toUpperCase(), 'matrixType': matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name === groups[0].name))[0].name.toUpperCase() });
          setClientInformation(response['client_information']);
          setSamples(response['submission']['metadata']['samples']);
          setSubmissionId(response['submission']['id']);
          setCodeId(response['submission']['code_id']);
          setIsActive(response['submission']['active']);
          if (response['submission']['active'])
            setLabData({ ...labData, 'sendDate': response['submission']['metadata']['sendDate'] });
          else
            setLabData({ 
              ...labData, 
              'sendDate': response['submission']['metadata']['sendDate'], 
              'receivingPersonnel': response['submission']['metadata']['receivingPersonnel'], 
              'conditionReceived': response['submission']['metadata']['conditionReceived'], 
              'dateTimeReceived': response['submission']['metadata']['dateTimeReceived'], 
              'storageLocation': response['submission']['metadata']['storageLocation'], 
              'signature': response['submission']['metadata']['signature'], 
              'assignedJobId': response['submission']['metadata']['assignedJobId'] 
            });
            setIsAuth(true);
        } else {
          setIsAuth(false);
        }
      } catch (error) {
        console.error('Error fetching submission:', error);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'groupType') {
      setFormData({ ...formData, [name]: value, 'matrixType': matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name.toUpperCase() === value.toUpperCase()))[0].name.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleLabDataChange = (e) => {
    const { name, value } = e.target;
    setLabData({ ...labData, [name]: value });
  };

  const handleSelect = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleTestsSelect = (selectedTests) => {
    setFormData({ ...formData, testsRequested: selectedTests });
  };

  const isValid = () => {
    return (
      formData.sampleName &&
      formData.lotOrBatch &&
      formData.quantityProvided &&
      formData.testsRequested.length > 0
    );
  };

  const isValidLabData = () => {
    return (
      labData.receivingPersonnel &&
      labData.conditionReceived &&
      labData.dateTimeReceived &&
      labData.storageLocation &&
      labData.signature
    );
  };

  const handleAddSample = () => {
    if (!isValid()) {
      return;
    }
    if (editingSample !== null) {
      const updatedSamples = samples.map((sample, index) =>
        index === editingSample ? formData : sample
      );
      setSamples(updatedSamples);
      setEditingSample(null);
    } else {
      setSamples([...samples, formData]);
    }
    setFormData({
      sampleName: '',
      groupType: groups[0].name.toUpperCase(), 
      matrixType: matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name === groups[0].name))[0].name.toUpperCase(),
      lotOrBatch: '',
      quantityProvided: 0,
      unit: 'ml',
      sampleStorageConditions: 'AMBIENT',
      testsRequested: [],
      turnaroundTime: 'STANDARD',
      notes: '',
    });
    setShowModal(false);
  };

  const handleEditSample = (index) => {
    setEditingSample(index);
    setFormData(samples[index]);
    setShowModal(true);
  };

  const handleDeleteSample = (index) => {
    const updatedSamples = samples.filter((_, i) => i !== index);
    setSamples(updatedSamples);
  };

  const handleCopySample = (index) => {
    setSamples([...samples, samples[index]]);
  };

  const submitWorkOrder = () => {
    setShowConfirmModal(true);
  };

  const handleSubmitAndPrint = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    try {
      // Create New Work Order
      const latest_work_order = await fetchLatestWorkOrder();
      let new_work_order_internal_id = parseInt(latest_work_order['internal_id'].match(/\d/g).join("")) + 1;
      let prepend = new_work_order_internal_id < 10000 ? 'ISO0' : 'ISO';
      
      const workOrder = {
        customer_id: clientInformation['customer_id'],
        internal_id: prepend + new_work_order_internal_id.toString(),
        received_at: labData.dateTimeReceived,
        job_status: 'RECEIVED',
        invoice_status: 'GENERATED',
        payment_method: 'Stripe Direct Debit',
        notes: ''
      };
      let response = await createWorkOrder(workOrder);
      const workOrderId = response['id'];
      setLabData({ ...labData, 'assignedJobId': prepend + new_work_order_internal_id.toString() });

      // Update Submission
      response = await updateSubmission(submissionId, codeId, samples, {...labData, 'assignedJobId': prepend + new_work_order_internal_id.toString()}, false);

      // Create Samples
      const latest_sample = await fetchLatestSample();
      let new_sample_internal_id = parseInt(latest_sample['internal_id'].match(/\d/g).join("")) + 1;

      for (const sample of samples) {
        response = await createSample({
          work_order_id: workOrderId,
          internal_id: 'I0' + new_sample_internal_id.toString(),
          metadata: {
            status: 'SAMPLE RECEIVED',
            group: sample.groupType,
            matrix: sample.matrixType,
            name: sample.sampleName,
            customer_lot_number: sample.lotOrBatch,
            sample_description: '',
            turnaround_time: sample.turnaroundTime,
            completed_at: Date.now(),
            issued_at: Date.now(),
            lab_notes: sample.notes,
            qa_notes: '',
            additional_report_notes: 'N/A',
            revision_history: 'rev 00 - Initial release.'
          }
        });
        const sampleId = response['id'];
        for (const testRequested of sample.testsRequested) {
          let testId = tests.filter((test) => testRequested.value == test.internal_id)[0]['id'];
          response = await createTest({
            sample_id: sampleId,
            test_template_id: testId,
            metadata: {}
          });
        }

        new_sample_internal_id = new_sample_internal_id + 1;
      }

      setIsActive(false);

    } catch (error) {
      console.error('Error submitting samples:', error);
    }
    setLoading(false);
  };

  const handleCode = (event) => {
    setCode(event.target.value);
  }

  const submitCode = (event) => {
    setCode(event.target.value);
    cookies.set('cora_science_admin', code, { path: '/' });
    window.location.reload(false);
  }

  return (
    <div>
      <Navbar bg="light" variant="light" expand="lg" style={{'borderBottom':'1px solid rgba(0,0,0,.1)','padding':'10px 15px'}}>
        <Container fluid>
          <Navbar.Brand>
            Cora Science Sample Check-In
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div style={{'padding': '30px 15px', 'background': 'rgba(203,203,210,.15)'}}>
        { !isAuth ?
          <Container fluid style={{ 'height': 'calc(100vh - 123px)', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}>
            <Row style={{ 'width': '50%' }}>
                <Col xs={12} md={12}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor='Code' style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>Code</Form.Label>
                    <Form.Control id='Code' value={code} onChange={e => handleCode(e)} />
                    </Form.Group>
                    <Button variant="primary" onClick={e => submitCode(e)}>Submit</Button>
                </Col>
            </Row>
          </Container>
        :
        tests.length > 0 ? 
        <Container fluid style={{ 'height': 'calc(100vh - 123px)'}}>
          <>
            {clientInformation && <>
            <h3>Client Information</h3>
            <div className="row">
                <div className="col-sm-6 col-sm-4 d-flex justify-content-left align-items-center">
                    <table style={{"width": "100%"}}>
                        <tbody>
                            <tr>
                                <th>Company Name:</th>
                                <td>{clientInformation.company_name}</td>
                            </tr>
                            <tr>
                                <th>Address:</th>
                                <td>{clientInformation.company_address_1}{clientInformation.company_address_2.length > 0 && `, ${clientInformation.company_address_2}`}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{`${clientInformation.company_address_city}, ${clientInformation.company_address_state} ${clientInformation.company_address_postal_code}`}</td>
                            </tr>
                            <tr>
                                <th>Contact:</th>
                                <td>{`${clientInformation.company_contact_first_name} ${clientInformation.company_contact_last_name}`}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{clientInformation.company_phone}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{clientInformation.company_email}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-6 col-sm-4 d-flex justify-content-left align-items-center">
                    <table style={{"width": "100%"}}>
                        <tbody>
                            <tr>
                                <th>Billing Address:</th>
                                <td>{clientInformation.billing_address_1}{clientInformation.billing_address_2.length > 0 && `, ${clientInformation.billing_address_2}`}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{`${clientInformation.billing_address_city}, ${clientInformation.billing_address_state} ${clientInformation.billing_address_postal_code}`}</td>
                            </tr>
                            <tr>
                                <th>Billing Contact:</th>
                                <td>{`${clientInformation.billing_contact_first_name} ${clientInformation.billing_contact_last_name}`}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{clientInformation.billing_phone}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{clientInformation.billing_email}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr style={{'margin': '20px 0px'}}/></>}
            <h3>Sample Information</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sample Name</th>
                  <th>Group Type</th>
                  <th>Matrix Type</th>
                  <th>Lot or Batch</th>
                  <th>Quantity Provided</th>
                  <th>Sample Storage Conditions</th>
                  <th>Tests Requested</th>
                  <th>Turnaround Time</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {samples.map((sample, index) => (
                  <tr key={index}>
                    <td>{sample.sampleName}</td>
                    <td>{sample.groupType}</td>
                    <td>{sample.matrixType}</td>
                    <td>{sample.lotOrBatch}</td>
                    <td>
                      {sample.quantityProvided} {sample.unit}
                    </td>
                    <td>{sample.sampleStorageConditions}</td>
                    <td>{sample.testsRequested.map((test) => test.label).join(', ')}</td>
                    <td>{sample.turnaroundTime}</td>
                    <td>{sample.notes}</td>
                    <td style={{'display': 'flex', 'justifyContent': 'space-evenly'}}>
                      <Button disabled={!isActive} variant="primary" onClick={() => handleEditSample(index)}>
                        Edit
                      </Button>
                      <Button disabled={!isActive} variant="danger" onClick={() => handleDeleteSample(index)}>
                        Delete
                      </Button>
                      <Button disabled={!isActive} variant="success" onClick={() => handleCopySample(index)}>
                        Copy
                      </Button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <Button disabled={!isActive} onClick={() => setShowModal(true)}>Add Sample</Button>

              <hr style={{'margin': '20px 0px'}}/>
              <h3>Laboratory Use</h3>
              <table>
                <thead>
                  <tr>
                    <th>Receiving Personnel</th>
                    <th>Condition Received</th>
                    <th>Date / Time Received</th>
                    <th>Storage Location</th>
                    <th>Signature</th>
                    <th>Assigned Job Id</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="receivingPersonnel"
                        value={labData.receivingPersonnel}
                        onChange={handleLabDataChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="conditionReceived"
                        value={labData.conditionReceived}
                        onChange={handleLabDataChange}
                      />
                    </td>
                    <td>
                      <input
                        type="datetime-local"
                        name="dateTimeReceived"
                        value={labData.dateTimeReceived}
                        onChange={handleLabDataChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="storageLocation"
                        value={labData.storageLocation}
                        onChange={handleLabDataChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="signature"
                        value={labData.signature}
                        onChange={handleLabDataChange}
                      />
                    </td>
                    <td>
                      <input
                        disabled
                        type="text"
                        name="assignedJobId"
                        value={labData.assignedJobId}
                        onChange={handleLabDataChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {samples.length > 0 && (
              <Button onClick={submitWorkOrder} disabled={!isValidLabData() || !isActive} className="ml-2" style={{'marginTop': '10px'}}>
                  Create Work Order
              </Button>
              )}
              
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{ editingSample !== null ? 'Edit Sample' : 'Add Sample' }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>

                    <Form.Group controlId="sampleName" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Sample Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="sampleName"
                        value={formData.sampleName}
                        onChange={handleFormChange}
                        isInvalid={!formData.sampleName}
                      />
                      <Form.Control.Feedback type="invalid">
                        Sample Name is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="groupType" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Group Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="groupType"
                        value={formData.groupType}
                        onChange={handleFormChange}
                      >
                      {
                        Object.values(groups).map((value, index) => (
                          <option key={index} value={value.name.toUpperCase()}>{value.name.toUpperCase()}</option>
                        ))
                      }
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="matrixType" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Matrix Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="matrixType"
                        value={formData.matrixType}
                        onChange={handleFormChange}
                      >
                        {
                          Object.values(matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name.toUpperCase() === formData.groupType.toUpperCase()))).map((value, index) => (
                            <option key={index} value={value.name.toUpperCase()}>{value.name.toUpperCase()}</option>
                          ))
                        }
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="lotOrBatch" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Lot or Batch</Form.Label>
                      <Form.Control
                        type="text"
                        name="lotOrBatch"
                        value={formData.lotOrBatch}
                        onChange={handleFormChange}
                        isInvalid={!formData.lotOrBatch}
                      />
                      <Form.Control.Feedback type="invalid">
                        Lot / Batch is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="quantityProvided" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Quantity Provided</Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type="number"
                          name="quantityProvided"
                          value={formData.quantityProvided}
                          onChange={handleFormChange}
                          className="mr-2"
                          isInvalid={!formData.quantityProvided}
                        />
                        <Form.Control.Feedback type="invalid">
                          Quantity Provided is required.
                        </Form.Control.Feedback>
                        <Dropdown onSelect={(value) => handleSelect('unit', value)}>
                          <Dropdown.Toggle variant="success">
                            {formData.unit || 'ml'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="ml">ml</Dropdown.Item>
                            <Dropdown.Item eventKey="g">g</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Form.Group>

                    <Form.Group controlId="sampleStorageConditions" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Sample Storage Conditions</Form.Label>
                      <Form.Control
                        as="select"
                        name="sampleStorageConditions"
                        value={formData.sampleStorageConditions}
                        onChange={handleFormChange}
                      >
                        <option key='AMBIENT' value='AMBIENT'>AMBIENT</option>
                        <option key='REFRIGERATED' value='REFRIGERATED'>REFRIGERATED</option>
                        <option key='FROZEN' value='FROZEN'>FROZEN</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="testsRequested" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Tests Requested</Form.Label>
                      <Select
                        isMulti
                        name="testsRequested"
                        options={tests.map((test) => ({ value: test.internal_id, label: test.internal_id }))}
                        onChange={handleTestsSelect}
                        value={formData.testsRequested.map((test) => ({
                          value: test.value,
                          label: test.label,
                        }))}
                        className={formData.testsRequested.length > 0 ? '' : 'is-invalid'}
                      />
                      {formData.testsRequested.length === 0 && (
                        <div className="invalid-feedback d-block">
                          Please select at least one test.
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="turnaroundTime" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Turnaround Time</Form.Label>
                      <Form.Control
                        as="select"
                        name="turnaroundTime"
                        value={formData.turnaroundTime}
                        onChange={handleFormChange}
                      >
                        <option key='STANDARD' value='STANDARD'>STANDARD</option>
                        <option key='RUSH-12HR' value='RUSH-12HR'>RUSH-12HR</option>
                        <option key='RUSH-24HR' value='RUSH-24HR'>RUSH-24HR</option>
                        <option key='RUSH-48HR' value='RUSH-48HR'>RUSH-48HR</option>
                        <option key='RUSH-72HR' value='RUSH-72HR'>RUSH-72HR</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="notes" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                      as="textarea"
                      name="notes"
                      value={formData.notes}
                      onChange={handleFormChange}
                      rows={4}
                      />
                    </Form.Group>

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                  </Button>
                  <Button disabled={!isValid()} variant="primary" onClick={handleAddSample}>
                    {editingSample !== null ? 'Update Sample' : 'Add Sample'}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Work Order Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Please confirm that the information you've submitted is accurate.</p>
                  <p style={{'marginTop': '15px'}}>
                    <span style={{'fontSize': '15px'}}>Make sure to <b style={{'fontSize': '25px'}}>print this page</b> for compliance purposes.</span>
                  </p>
                  
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleSubmitAndPrint}>
                    Submit and Print
                  </Button>
                </Modal.Footer>
              </Modal>
          </>
        </Container>
        :
        <Container fluid style={{ 'height': 'calc(100vh - 123px)', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}>
          <Row className="justify-content-md-center" style={{ 'width': '50%' }}>
            <Col  xs={12} md={12}>
              <Alert variant="info">
                Submission is invalid or has already been checked in.
              </Alert>
            </Col>
          </Row>
        </Container>
        }
      </div>
    </div>
  );
};

export default CheckIn;